<template>
  <div class="page">
    <img
      src="https://directimg.miaocang.cc/mpImg/standardization@2x.png"
      draggable="false"
      oncontextmenu="return false;"
      class="unselectable"
    />
  </div>
</template>

<script>
export default {
  name: 'Standardization'
}
</script>

<style lang="less" scoped>
.page {
  width: 100vw;
  background-color: #8fd9b3;
  img {
    width: 100%;
  }
}
</style>
